<template>
	<div class="guide">
		<div class="banner">
			<img src="../assets/3.jpg" class="banner_img" />
		</div>
		<div class="title">
			<h1>交付指南</h1>
		</div>
		<div>
			<div class="content">
				
					<div class="list" @click="show1">
						<h2 :class=" !hide1?'h2':'ac1'">交付流程</h2>
						<img src="../assets/105.png" :class="!hide1?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide1">
							<p>
								跑跑太空熊非常重视代理商的后端管理体验，合同签署流程完毕后，交付专员会为您创建一个专属服务的微信群，为您量身订制整个后续合作服务，给您一个全新的交付体验。
							</p>
							<p>
								交付流程为：订金交付-合同签署-尾款交付-柜子入场-首月试运营-正式运营，六个阶段，详细交付流程及注意事项请联系招商经理。
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close1"/>
							</div>				
						</div>
					</transition>
				
				
				<div class="list" @click="show2">
					<h2 :class=" !hide2?'h2':'ac1'">点位保险</h2>
					<img src="../assets/105.png" :class="!hide2?'icon':'ac2'" />
				</div>
				<transition name="bounce">
					<div class="hideBox" v-if="hide2">
						<p>
							请提供您的身份证及商场详细地址与招商经理，
							点位保险为额外套餐，费⽤在600-1000元/年 不
							等。
						</p>
						<div style="height: 0.4rem;">
							<img src="../assets/16.png" class="close" @click="close2"/>
						</div>
					</div>
				</transition>
				
				
				<div class="list" @click="show3">
					<h2 :class=" !hide3?'h2':'ac1'">精品周边</h2>
					<img src="../assets/105.png" :class="!hide3?'icon':'ac2'" />
				</div>
				<transition name="bounce">
					<div class="hideBox" v-if="hide3">
						<div class="flex_img">
							<img src="../assets/goods/b1.jpg" class="goods_img" />
							<img src="../assets/goods/b4.jpg" class="goods_img" />
							<img src="../assets/goods/cl2.jpg" class="goods_img" />
							<img src="../assets/goods/cl3.jpg" class="goods_img" />
							<img src="../assets/goods/cup4.jpg" class="goods_img" />
							<img src="../assets/goods/k4.jpg" class="goods_img" />
							<img src="../assets/goods/p2.jpg" class="goods_img" />
						</div>
						<div style="height: 0.4rem;">
							<img src="../assets/16.png" class="close" @click="close3"/>
						</div>				
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'delGuide',
		data(){
			return {
				hide1: false,
				hide2: false,
				hide3: false
			}
		},
		methods:{
			show1(){
				this.hide1 = true
			},
			close1(){
				this.hide1 = false
			},
			show2(){
				this.hide2 = true
			},
			close2(){
				this.hide2 = false
			},
			show3(){
				this.hide3 = true
			},
			close3(){
				this.hide3 = false
			}
		}
	}
</script>

<style scoped="scoped">
	.banner{
		width: 100%;
		height: 2rem;
		
	}
	.banner_img{
		width: 100%;
		height: 2rem;
	}
	.title{
		width: 100%;
		margin-top: 0.2rem;
	}
	.title h1{
		font-size: 0.24rem;
		font-weight: bold;
		color: #222326;
		text-align: left;
		box-sizing: border-box;
		padding-left: 0.2rem;
	}
	
	.content{
		position: relative;
		padding-left: 20px;
    padding-right: 20px;
	}
	.list{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #828C96;
		transition: all 0.5s; 
	}
	.h2{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: normal;
	}
	.ac1{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: bold;
	}
	.icon{
		width: 0.2rem;
		height: 0.2rem;
	}
	.ac2{
		opacity: 0;
	}
	.hideBox{
		box-sizing: border-box;
		padding: 0.2rem 0;
		height: 100%;
	}
	.hideBox p{
		font-size: 0.13rem;
	  color: #828C96;
    margin-top: 0.1rem;
	  line-height: 0.22rem;
    font-weight: 300;
		text-align: left;
	}
	.close{
		width: 0.3rem;
		height: 0.3rem;
		transform: translateY(-0.5rem);
	}
	.flex_img{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
	.goods_img{
		width: 45%;
		margin-top: 0.15rem;
	}
	.bounce-enter-active {
	  animation: bounce-in .5s;
	}
	.bounce-leave-active {
	  animation: bounce-in .5s reverse;
	}
	@keyframes bounce-in {
	  0% {
	    transform: scale(0);
	  }
	  50% {
	    transform: scale(1.5);
	  }
	  100% {
	    transform: scale(1);
	  }
	}
</style>