<template>
	<div class="question">
		<div class="banner">
			<img src="../assets/4.jpg" class="banner_img" />
		</div>
		<div class="title">
			<h1>常见问题</h1>
		</div>
		<div>
				<div class="content">
					
						<div class="list" @click="show1">
							<h2 :class=" !hide1?'h2':'ac1'">管理费包括什么</h2>
							<img src="../assets/105.png" :class="!hide1?'icon':'ac2'" />
						</div>
						<transition name="bounce">
							<div class="hideBox" v-if="hide1">
								<h3>
									管理的便捷性
								</h3>
								<p>
									1. 智能柜采用内置的进口4G智能芯片与内置形记
									账器，实时精确计时并回传数据到服务器中控系
									统；
								</p>
								<p>
									2. 小程序App结合人工智能与区块链技术，能够
									实时记录并监控每一个商圈区域的所有童车运营
									数据，实现即时无人化记账管理；
								</p>
								<p>
									3. 无人值守，避免了人工高额人力成本资源，避
									免了无系统管理所带来的现金流失风险，避免了
									管理不善扩张速度慢等问题；
								</p>
								<p>
									4. 运维团队工作日在线服务，支持远程监测运营
									数据，并提出有效的标价方案；
								</p>
								<p>
									5. 人工客服24小时在线提供客服服务；
								</p>
								
								<h3>
									技术的支持
								</h3>
								<p>
									1. 软件服务器维护及升级，数据库维护；
								</p>
								<p>
									2. 区块链技术大数据智能分析报价系统实现智能
									化动态收费；
								</p>
								<p>
									3. 负载均衡系统，结合分布式DDoS/CC防护系
									统，再大的客流量也不会耽误童车的运营；
								</p>
								<p>
									4. 在线记账器管理系统与后台收益查询系统，城
									市合伙人配备超级管理用户；
								</p>
								<p>
									5. 合作期限内支持硬件及软件的免费维修与升
									级；
								</p>
								
								<h3>
									品牌的运营
								</h3>
								<p>
									1. 提供小程序运营与升级，小程序内营销活动组
									织；
								</p>
								<p>
									2. 定期每个城市社区引流以及相关推广活动的举
									办、儿童心中的网红打卡点；
								</p>
								<p>
									3. 线上媒体平台宣传，抖音，B站，朋友圈，小
									红书等广告投放，商圈引流；每个城市合伙人会
									开设认证抖音账号，我们可代运营；
								</p>
								<div style="height: 0.4rem;">
									<img src="../assets/16.png" class="close" @click="close1"/>
								</div>				
							</div>
						</transition>
					
					
					<div class="list" @click="show2">
						<h2 :class=" !hide2?'h2':'ac1'">童车柜体与车辆的运费</h2>
						<img src="../assets/105.png" :class="!hide2?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide2">
							<p>
								跑跑太空熊不负责任何物流费用，代理商可以自行
								寻找当地物流公司，我司可提供德邦物流及安
								能物流等第三方平台对接物流事宜。
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close2"/>
							</div>
						</div>
					</transition>
					
					
					<div class="list" @click="show3">
						<h2 :class=" !hide3?'h2':'ac1'">零配件的快递费</h2>
						<img src="../assets/105.png" :class="!hide3?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide3">
							<p>
								跑跑太空熊所有零配件均为货到付款
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close3"/>
							</div>				
						</div>
					</transition>
					
					<div class="list" @click="show4">
						<h2 :class=" !hide4?'h2':'ac1'">零配件收货地址</h2>
						<img src="../assets/105.png" :class="!hide4?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide4">
							<p>
								王鹏
							</p>
							<p>
								15279745075
							</p>
							<p>
								深圳市南山区桂湾片区前海壹号B座1301B
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close4"/>
							</div>				
						</div>
					</transition>
					
					<div class="list" @click="show5">
						<h2 :class=" !hide5?'h2':'ac1'">优惠券</h2>
						<img src="../assets/105.png" :class="!hide5?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide5">
							<p>
								优惠券为满X元减X元，具体请移步管理员模式下
								的优惠券申请表
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close5"/>
							</div>				
						</div>
					</transition>
					
					<div class="list" @click="show6">
						<h2 :class=" !hide6?'h2':'ac1'">充值会员</h2>
						<img src="../assets/105.png" :class="!hide6?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide6">
							<p>
								全国所有充值会员共用同一个线上会员系统，充
								值后会员均会使用余额支付，支付完成后资金划
								分给会员当时消费所在场地的所属代理商帐号
							</p>
							<p>
								举例1: 会员在北京充值，在上海消费，则入帐金
								额算上海代理商。
							</p>
							<p>
								举例2:会员充值100元，赠送20元，当前余额120
								元。会员在武汉代理商处使用消费60元，实际扣
								款为充值的50元加上赠送的10元（虚拟），代理
								商和总平台总共得到50元与虚拟的10元，则为实
								际50元。50元扣除20%管理费，代理商到帐40
								元，总平台到帐10元，合计50元。虚拟的10元为
								不存在的金额。
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close6"/>
							</div>				
						</div>
					</transition>
				</div>
			</div>
		</div>
</template>

<script>
	export default {
		name: 'normalQus',
		data(){
			return {
				hide1: false,
				hide2: false,
				hide3: false,
				hide4: false,
				hide5: false,
				hide6: false
			}
		},
		methods:{
			show1(){
				this.hide1 = true
			},
			close1(){
				this.hide1 = false
			},
			show2(){
				this.hide2 = true
			},
			close2(){
				this.hide2 = false
			},
			show3(){
				this.hide3 = true
			},
			close3(){
				this.hide3 = false
			},
			show4(){
				this.hide4 = true
			},
			close4(){
				this.hide4 = false
			},
			show5(){
				this.hide5 = true
			},
			close5(){
				this.hide5 = false
			},
			show6(){
				this.hide6 = true
			},
			close6(){
				this.hide6 = false
			}
		}
	}
</script>

<style scoped="scoped">
	.banner{
		width: 100%;
		height: 2rem;
		
	}
	.banner_img{
		width: 100%;
		height: 2rem;
	}
	.title{
		width: 100%;
		margin-top: 0.2rem;
	}
	.title h1{
		font-size: 0.24rem;
		font-weight: bold;
		color: #222326;
		text-align: left;
		box-sizing: border-box;
		padding-left: 0.2rem;
	}
	
	.content{
		position: relative;
		padding-left: 20px;
	  padding-right: 20px;
	}
	.list{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #828C96;
		transition: all 0.5s; 
	}
	.h2{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: normal;
	}
	h3{
		font-size: 0.16rem;
		color: #505558;
		margin-top: 0.1rem;
	}
	.ac1{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: bold;
	}
	.icon{
		width: 0.2rem;
		height: 0.2rem;
	}
	.ac2{
		opacity: 0;
	}
	.hideBox{
		box-sizing: border-box;
		padding: 0.2rem 0;
		height: 100%;
	}
	.hideBox p{
		font-size: 0.13rem;
	  color: #828C96;
	  margin-top: 0.1rem;
	  line-height: 0.22rem;
	  font-weight: 300;
		text-align: left;
	}
	.close{
		width: 0.3rem;
		height: 0.3rem;
		transform: translateY(-0.5rem);
	}
	.flex_img{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
	.goods_img{
		width: 45%;
		margin-top: 0.15rem;
	}
	.bounce-enter-active {
	  animation: bounce-in .5s;
	}
	.bounce-leave-active {
	  animation: bounce-in .5s reverse;
	}
	@keyframes bounce-in {
	  0% {
	    transform: scale(0);
	  }
	  50% {
	    transform: scale(1.5);
	  }
	  100% {
	    transform: scale(1);
	  }
	}
</style>