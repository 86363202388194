<template>
	<div class="service">
		<div class="banner">
			<img src="../assets/5.jpg" class="banner_img" />
		</div>
		<div class="title">
			<h1>售后服务</h1>
		</div>
		<div>
				<div class="content">
					
						<div class="list" @click="show1">
							<h2 :class=" !hide1?'h2':'ac1'">日常维护微信群</h2>
							<img src="../assets/105.png" :class="!hide1?'icon':'ac2'" />
						</div>
						<transition name="bounce">
							<div class="hideBox" v-if="hide1">
								<p>
									维护人员不少于软件工程师2位，硬件工程师1
									位，设计师1位，客服负责人2位，产品运营负责
									人1位，及其他运维负责人3位；总人数不少于10
									位专职人员对于售后进行线上运维指导。
								</p>
								<div style="height: 0.4rem;">
									<img src="../assets/16.png" class="close" @click="close1"/>
								</div>				
							</div>
						</transition>
					
					
					<div class="list" @click="show2">
						<h2 :class=" !hide2?'h2':'ac1'">日常清洁</h2>
						<img src="../assets/105.png" :class="!hide2?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide2">
							<p>
								代理商每月需到现场一次进行简单的清洁处理。
								柜内有UV消毒灯杀菌，但灰尘等杂物需定期打
								扫。
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close2"/>
							</div>
						</div>
					</transition>
					
					
					<div class="list" @click="show3">
						<h2 :class=" !hide3?'h2':'ac1'">抖音代运营</h2>
						<img src="../assets/105.png" :class="!hide3?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide3">
							<p>
								深圳跑跑太空熊提供免费的抖音代运营服务（非
								代理商价格为9800元/年）。需向运营负责人提
								供自己或亲戚手机号作为注册抖音的手机号（需
								能接受验证码）。
							</p>
							<p>
								服务网点：3000粉丝
							</p>
							<p>
								区(县级)代理：10000粉丝
							</p>
							<p>
								地级市代理：30000粉丝
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close3"/>
							</div>				
						</div>
					</transition>
					
					<div class="list" @click="show4">
						<h2 :class=" !hide4?'h2':'ac1'">门店装修</h2>
						<img src="../assets/105.png" :class="!hide4?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide4">
							<p>
								首次合作的加盟商，前5个商场点位，总公司提
								供5次每次价值3万元的门店设计，开业装潢物料
								设计，优惠券活动的大礼包。
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close4"/>
							</div>
						</div>
					</transition>
					
					<div class="list" @click="show5">
						<h2 :class=" !hide5?'h2':'ac1'">首月试运营活动策划</h2>
						<img src="../assets/105.png" :class="!hide5?'icon':'ac2'" />
					</div>
					<transition name="bounce">
						<div class="hideBox" v-if="hide5">
							<p>
								对首月试运营期间，大数据定位倒流增加点位客
								流量与营业额。使用拉新手段，线上线下发放优
								惠券及充值会员的营销方案。
							</p>
							<div style="height: 0.4rem;">
								<img src="../assets/16.png" class="close" @click="close5"/>
							</div>
						</div>
					</transition>
				</div>
			</div>
		</div>
</template>

<script>
	export default {
		name: 'aftService',
		data(){
			return {
				hide1: false,
				hide2: false,
				hide3: false,
				hide4: false,
				hide5: false
			}
		},
		methods:{
			show1(){
				this.hide1 = true
			},
			close1(){
				this.hide1 = false
			},
			show2(){
				this.hide2 = true
			},
			close2(){
				this.hide2 = false
			},
			show3(){
				this.hide3 = true
			},
			close3(){
				this.hide3 = false
			},
			show4(){
				this.hide4 = true
			},
			close4(){
				this.hide4 = false
			},
			show5(){
				this.hide5 = true
			},
			close5(){
				this.hide5 = false
			}
		}
	}
</script>

<style scoped="scoped">
	.banner{
		width: 100%;
		height: 2rem;
		
	}
	.banner_img{
		width: 100%;
		height: 2rem;
	}
	.title{
		width: 100%;
		margin-top: 0.2rem;
	}
	.title h1{
		font-size: 0.24rem;
		font-weight: bold;
		color: #222326;
		text-align: left;
		box-sizing: border-box;
		padding-left: 0.2rem;
	}
	
	.content{
		position: relative;
		padding-left: 20px;
	  padding-right: 20px;
	}
	.list{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #828C96;
		transition: all 0.5s; 
	}
	.h2{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: normal;
	}
	.ac1{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: bold;
	}
	.icon{
		width: 0.2rem;
		height: 0.2rem;
	}
	.ac2{
		opacity: 0;
	}
	.hideBox{
		box-sizing: border-box;
		padding: 0.2rem 0;
		height: 100%;
	}
	.hideBox p{
		font-size: 0.13rem;
	  color: #828C96;
	  margin-top: 0.1rem;
	  line-height: 0.22rem;
	  font-weight: 300;
		text-align: left;
	}
	.close{
		width: 0.3rem;
		height: 0.3rem;
		transform: translateY(-0.5rem);
	}
	.flex_img{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
	.goods_img{
		width: 45%;
		margin-top: 0.15rem;
	}
	.bounce-enter-active {
	  animation: bounce-in .5s;
	}
	.bounce-leave-active {
	  animation: bounce-in .5s reverse;
	}
	@keyframes bounce-in {
	  0% {
	    transform: scale(0);
	  }
	  50% {
	    transform: scale(1.5);
	  }
	  100% {
	    transform: scale(1);
	  }
	}
</style>
