<template>
	<div class="video">
		<div class="bar">
			<div :class="nav == 1 ? 'ac' : '' " @click="switchTab(1)">软件视频</div>
			<div :class="nav == 2 ? 'ac' : '' " @click="switchTab(2)">硬件视频</div>
		</div>
		<div class="banner">
			<video-player class="video-player vjs-custom-skin banner_video" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
			<div class="v_title">{{vTitle}}</div>
		</div>
		<div class="container">
			<div v-for="(item,i) in lists" :key="i" class="list" @click="show(i)">
				<div class="flex_row">
					<h2 :class=" index == i?'ac1':'h2'">{{item.titleIn}}</h2>
					<img src="../assets/105.png" :class="index == i?'ac2':'icon'" />
				</div>
												
				<transition name="bounce">
					<div class="hideBox" v-show="index == i || item.show">
						<div class="flex_img">
							<div class="li" v-for="(ls,m) in item.dataIn" @click="tab(ls)">
								<img :src="ls.image" class="goods_img" />
								<div class="s_title">{{ls.name}}</div>
								
							</div>
						</div>
					</div>
				</transition>
			</div>
			
		</div>
		<img src="../assets/16.png" v-if="showClose" class="close" @click="hide"/>
	</div>
</template>

<script>
	export default {
		name: 'useVideo',
		data(){
			return {
				playerOptions: {
					playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "",
						src: '', //url地址  
						// src: "" //url地址
					}],
					poster: "", //你的封面地址
					// width: document.documentElement.clientWidth,
					notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true //全屏按钮
					},
				},
				vTitle: '',
				lists: '',
				index: -1,
				showClose: false,
				nav: 1,
				curr_i: ''
			}
			
			
		},
		methods:{
			show(i){
				this.index = i
				this.curr_i = i
				this.showClose = true
				this.lists[i].show = true
				console.log(this.lists[i].show)
			},
			hide(){
				this.index = -1
				this.showClose = false
				let list = this.lists
				for(let i =0 ;i < list.length; i++){
					list[i].show = false
				}
				console.log(list)
				this.lists = list
			},
			tab(url){
				// console.log(url)
				this.playerOptions.sources[0].src = url.url
				this.playerOptions.poster = url.image
				this.vTitle = url.name
			},
			switchTab(i){
				this.index = -1
				let j = i-1
				this.nav = i
				this.$axios.post('/manual/get-specification',{
					'type': i
				}).then(res => {
					console.log(res)
					if(res.data.code == 200){
						let result = res.data.data
						// console.log(result)
						this.vTitle = result[j].dataOut[0].dataIn[0].name
						this.playerOptions.sources[0].src = result[j].dataOut[0].dataIn[0].url
						this.playerOptions.poster = result[j].dataOut[0].dataIn[0].image
						this.lists = result[j].dataOut
						let list = this.lists
						for(let i =0 ;i < list.length; i++){
							list[i].show = false
						}
						this.lists = list
					}
				}).catch(err => {
					console.log(err)
				})
			}
		},
		mounted(){
			this.$axios.post('/manual/get-specification',{
				'type': 1
			}).then(res => {
				// console.log(res)
				if(res.data.code == 200){
					let result = res.data.data
					console.log(result)
					this.vTitle = result[0].dataOut[0].dataIn[0].name
					this.playerOptions.sources[0].src = result[0].dataOut[0].dataIn[0].url
					this.playerOptions.poster = result[0].dataOut[0].dataIn[0].image
					this.lists = result[0].dataOut
					let list = this.lists
					console.log(list)
					for(let i =0 ;i < list.length; i++){
						list[i].show = false
					}
					console.log(list)
					this.lists = list
				}
			}).catch(err => {
				console.log(err)
			})
		}
	}
</script>

<style scoped="scoped">
	.video{
		width: 100%;
		height: 100vh;
		position: relative;
		background-color: #f4f4f4;
	}
	.banner{
		width: 100%;
		box-sizing: border-box;
		padding: 0.2rem;
		background-color: #fff;
	}
	.banner_video{
		width: 100%;
		margin: 0 auto;
		border-radius: 0.2rem;
	}
	.v_title{
		font-size: 0.16rem;
		text-align: left;
		box-sizing: border-box;
		padding: 0.1rem 0;
	}
	.container{
		background-color: #f4f4f4;
	}
	.list{
		/* display: flex;
		justify-content: space-between;
		align-items: center; */
		/* border-bottom: 1px solid #828C96; */
		transition: all 0.5s; 
		box-sizing: border-box;
		padding: 0 0.2rem;
	}
	.flex_row{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #828C96;
		
	}
	.h2{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: normal;
	}
	.ac1{
		height: 0.5rem;
		line-height: 0.5rem;
		font-size: 0.16rem;
		font-weight: bold;
	}
	.icon{
		width: 0.2rem;
		height: 0.2rem;
	}
	.ac2{
		opacity: 0;
	}
	.hideBox{
		box-sizing: border-box;
		padding: 0.2rem 0;
		height: 100%;
	}
	.close{
		position: fixed;
		bottom: 5%;
		left: 50%;
		width: 0.3rem;
		height: 0.3rem;
		transform: translateY(-50%);
		transform: translateX(-50%);
	}
	.flex_img{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
	}
	.li{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 45%;
	}
	.goods_img{
		width: 100%;
		margin-top: 0.15rem;
	}
	.s_title{
		font-size: 0.16rem;
		margin-top: 0.1rem;
	}
	.bounce-enter-active {
	  animation: bounce-in .5s;
	}
	.bounce-leave-active {
	  animation: bounce-in .5s reverse;
	}
	@keyframes bounce-in {
	  0% {
	    /* transform: scale3d(0); */
			transform: scale(0);
	  }
	  50% {
	    /* transform: scale3d(1.5); */
			transform: scale(1.2);
	  }
	  100% {
	    /* transform: scale3d(1); */
			transform: scale(1);
	  }
	}
	.none{
		color: #222;
	}
	.bar{
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fff;
		border-bottom: 1px solid #828C96;
	}
	.bar > div{
		width: 50%;
		font-size: 0.2rem;
		box-sizing: border-box;
		padding: 0.1rem 0.1rem;
	}
	.ac{
		border-bottom: 3px solid #000;
	}
</style>